<template>
	<div class="goods_zong">
		<div class="goods_left">
			<div class="poo">商品管理</div>
			<div class="xiala_item">
				<div class="xiala_box" @click="changeTab(item.path)" :class="{'active':item.path==act}"
					v-for="(item,index) in list" :key="index">
					<router-link :to="item.path" class="xiala_box" :class="{'active':item.path==act}">{{item.name}}
					</router-link>
				</div>
			</div>
		</div>
		<div class="right1">
			<router-view />
		</div>
	</div>

</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				list: [{
						name: "发布宝贝",
						path: "/goods/select_class",
					},
					{
						name: "我的宝贝",
						path: "/goods/mygoods",
					},
					{
						name: "全部商品",
						path: "/goods/goods_list",
					},
					{
						name: "商品审核",
						path: "/goods/commodity_audit",
					},
					
				],
				act: 0,
			}
		},
		created() {
			// let a =this.$route.query.act //接收传递过来的query参数
			// if(!this.kj){
			//     if(a != null && a != undefined && a!=''){
			//     this.act = a
			//     console.log('a',a)
			//     }
			//   let b = this.$route.query.is_create //接收传递过来的query参数
			//   if(b != null && b != undefined && b!=''){
			//     this.is_create = b
			//     console.log('b',b)
			//   }
			// }
			// console.log(this.$route.path)
			this.act = this.$route.path
		},
		updated() {
			let index = this.$route.path
			this.changeTab(index);

		},
		methods: {
			changeTab(index) {
				this.act = index
			},
		}
	}
</script>

<style scoped>
	@import './goods.css';
</style>
